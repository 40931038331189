import React from 'react';
import { graphql, Link } from 'gatsby';
import Seo from '../components/seo';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';

const TagTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const posts = data.allMarkdownRemark.nodes;
  const recentPosts = data.recentPosts?.edges || [];

  return (
    <>
      <Navigation />
      <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <main className="lg:col-span-8 lg:col-start-1">
              <h1 className="text-4xl text-gray-900 mb-12">Posts tagged with "{tag}"</h1>
              <div className="space-y-12">
                {posts.map(post => (
                  <article key={post.id} className="border-b border-gray-200 pb-8 last:border-b-0">
                    <div className="space-y-4">
                      <div className="text-sm text-gray-600">{post.frontmatter.date}</div>
                      <Link to={post.fields.slug} className="block group">
                        <h2 className="text-4xl text-brand-600 leading-tight tracking-tight">{post.frontmatter.title}</h2>
                      </Link>
                      <div className="flex flex-wrap gap-2">
                        {post.frontmatter.tags.map(t => (
                          <Link
                            key={t}
                            to={`/tags/${t.toLowerCase().replace(/\s+/g, '-')}`}
                            className="text-sm text-gray-600 hover:text-brand-600"
                          >
                            #{t}
                          </Link>
                        ))}
                      </div>
                      <p className="text-gray-700 leading-relaxed">{post.excerpt}</p>
                      <Link 
                        to={post.fields.slug} 
                        className="inline-block text-brand-600 hover:text-brand-700 hover:underline"
                      >
                        Read More →
                      </Link>
                    </div>
                  </article>
                ))}
              </div>
            </main>

            {/* Sidebar */}
            <aside className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-8 space-y-8">
                {/* Recent Posts */}
                <div className="border-l-4 border-brand-200 pl-4">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">Latest Writings</h2>
                  <div className="space-y-3">
                    {recentPosts.map(({ node }) => (
                      <Link 
                        key={node.fields.slug}
                        to={node.fields.slug}
                        className="block text-gray-700 hover:text-brand-600"
                      >
                        {node.frontmatter.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TagTemplate;

export const query = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
          tags
        }
      }
    }
    recentPosts: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`; 